.main-container{
  background-color:#f2f4f8;
  overflow:auto;
  padding-right:30px;
  height:calc(100vh - 55px);
}

.spot{
  background-color: antiquewhite; 
  cursor: pointer; 
}

.spot:not(.icon-aisle):hover, .hovered, .selected {
  opacity: .3;  
}


.aisle{
  background-color: #999999;
}

.one-box{
  width: 50px;
  height: 40px;
  border: 1px solid black;
}

.occupated{
  background-color: #0d4c86;
}

.one-level *{
  cursor: pointer;
}
.label-horizontal{
  width: 50px;
  text-align: center;
}

.label-vertical{
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-aisle{
  background-color: #eb5f19;
  background-size: contain;
  background-position: center;
  cursor: inherit;
  background-repeat: no-repeat;
}

.elevator{
  background-image: url('./img/lift.png');
}

.mover{
  background-image: url('./img/mover.png');
}

.shuttle{
  background-image: url('./img/shuttle.png');
}
.stacked{
  background-image: url('./img/stacked.png');
}

.header{
  height: 55px;
  background-color: #004887;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image-container{
  height: 100%;
  background-color: white;
  padding: 5px 20px;
}

.image-container>img{
  height: 100%;
  width: auto;
}

.sidebar{
  width: 220px;
  min-width: 220px;
  background-color: transparent;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: content-box;
}

.sidebar-item{
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
}

*:not(.activity-icon>*){
  font-family: "Kumbh Sans" !important;
  color: #3E4B5B;
}

.activity-icon{
  color:#5BE82C
}

.small-text-user{
  display: block;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 1px;
}

.header-text-user{
  font-weight: 600;
    font-size: 1.1rem;
    margin: 0px;
}

/* sidebar */

.sidebar-list-title{
  text-transform: uppercase;
  color: #f97e32;
  font-weight: 600;
}

.sidebar-list{
  list-style: none;
  margin: 0;
  padding: 5px;
}

.sidebar-list li{
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.sidebar-list i, .login-container i{
  font-weight: 600;
  font-size: 1.4rem !important;
  padding-right: 10px;
  color: #f97e32 ;
}

/* Customize the label (the container) */
.radio-container { 
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 25px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color:#f97e32;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.sidebar-list li:not(.radio-container):not(.button-container):hover{
  transform: translateX(25px);
  transition: all 0.3s ease-in-out;
}

.button-container button{
  width: 100px;
  border: none;
  background-color: #f97e32;
  padding: 10px;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
}

.button-container button i{
  color: white;
  transition: all 0.2s ease-in-out;
}

.button-container button:hover, .button-submit:hover,.login-container button:hover{
  transform: scale(1.1);
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: #f97e32;
  background-color: white;
  transition: all 0.2s ease-in-out;

}

.button-container button:hover i{
  transition: all 0.2s ease-in-out;
  color: #f97e32;
}

.button-container button:focus, .button-submit:focus,.login-container button:focus{
  outline: none;
}

.border-bottom{
  border-bottom: 1px solid rgba(0,0,0,0.15);
}

.button-submit,.login-container button{
  border: none;
  background-color: #f97e32;
  padding: 10px;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.login-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 250px;
  background-color: rgba(128,128,128,0.3);
  border-radius: 10px;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.65);
}

.login-container form{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container input{
  height: 25px;
  border: 1px solid rgba(128,128,128,0.6);
  border-radius: 6px;
  padding: 5px;
}

.login-container input:focus{
  outline-color: #f97e32;
}